var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"api":"offerMatch","filters":_vm.filters,"headers":_vm.headers,"popup":_vm.popup,"edit":_vm.edit,"save":_vm.save,"use-filters":"offer","ignore-status":""},on:{"click:item":function($event){return _vm.edit( $event.item.item )}},scopedSlots:_vm._u([{key:"item.value.offer",fn:function(ref){
var value = ref.value;
return [(value.customer)?[_c('p',{staticClass:"mb-1"},[_c('router-link',{attrs:{"to":("/customer?id=" + (value.customer.id))}},[_vm._v(" "+_vm._s(value.customer.name)+" ")])],1),(value.customer.mail)?_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(value.customer.mail)+" ")]):_vm._e(),(value.customer.phone)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(value.customer.phone)+" ")]):_vm._e()]:_vm._e()]}},{key:"item.value.user",fn:function(ref){
var value = ref.value;
return [(value)?[_c('p',{staticClass:"mb-1"},[_c('router-link',{attrs:{"to":("/users/" + (value.id))}},[_vm._v(" "+_vm._s(value.name)+" ")])],1),(value.email)?_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(value.email)+" ")]):_vm._e(),(value.phone)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(value.phone)+" ")]):_vm._e()]:_vm._e()]}},{key:"item.value.status",fn:function(ref){
var _obj;

var value = ref.value;return [_c('span',{class:( _obj = {}, _obj[((_vm.bookingColors[value]) + "--text")] = !!_vm.bookingColors[value], _obj )},[_vm._v(" "+_vm._s(_vm.bookingStatus[value])+" ")])]}},{key:"form",fn:function(){return [_c('offer-match-form',{ref:"form",attrs:{"value":_vm.model,"status-items":_vm.statusItems,"is-new":_vm.isNew,"can-edit":_vm.canEdit,"save":_vm.save}})]},proxy:true},{key:"footer",fn:function(){return [_c('v-spacer'),_c('btn',{attrs:{"color":"primary","loading":_vm.exporting},on:{"click":function($event){$event.stopPropagation();return _vm.exportData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.export'))+" ")]),(_vm.canEdit)?_c('btn',{staticClass:"ml-2",on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('offerMatch.btn.new'))+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }