<template>
  <table-view
    ref="table"
    api="offerMatch"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :edit="edit"
    :save="save"
    use-filters="offer"
    @click:item="edit( $event.item.item )"
    ignore-status
  >
  <template v-slot:item.value.offer="{ value }">
    <template v-if="value.customer">

      <p class="mb-1">
        <router-link :to="`/customer?id=${value.customer.id}`">
          {{ value.customer.name }}
        </router-link>
      </p>

      <p class="mb-1" v-if="value.customer.mail">
        {{ value.customer.mail }}
      </p>

      <p class="mb-0" v-if="value.customer.phone">
        {{ value.customer.phone }}
      </p>

    </template>
  </template>
  <template v-slot:item.value.user="{ value }">
    <template v-if="value">

      <p class="mb-1">
        <router-link :to="`/users/${value.id}`">
          {{ value.name }}
        </router-link>
      </p>

      <p class="mb-1" v-if="value.email">
        {{ value.email }}
      </p>

      <p class="mb-0" v-if="value.phone">
        {{ value.phone }}
      </p>

    </template>
  </template>
  <template v-slot:item.value.status="{ value }">

    <span :class="{[`${bookingColors[value]}--text`]: !!bookingColors[value]}">
      {{ bookingStatus[value] }}
    </span>

  </template>
  <template v-slot:form>

    <offer-match-form
      ref="form"
      :value="model"
      :status-items="statusItems"
      :is-new="isNew"
      :can-edit="canEdit"
      :save="save"
    />

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn v-if="canEdit" class="ml-2" @click.stop="edit()">
      {{ $t('offerMatch.btn.new') }}
    </btn>

  </template>
  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import OfferMatchForm from './Form';
import { displayDate } from '@/utils';
import { mapState } from 'vuex';
import { ICONS, BOOKING_STATUS, BOOKING_STATUS_COLOR, USER_ROLES } from '@/utils/constants';

export default {
  name: 'OfferMatch',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('offerMatch.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => {
    return {
      title: i18n.t('offerMatch.title'),
      icon: ICONS.offerMatch,
    }
  },
  components: { TableView, OfferMatchForm },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    bookingColors: BOOKING_STATUS_COLOR,
    isNew: false
  }),
  computed: {
    ...mapState( 'auth', [ 'user' ]),
    canEdit() {
      return ![
        USER_ROLES.EDITOR,
        USER_ROLES.USER
      ].includes( this.user.role );
    },
    popup() {
      return {
        maxWidth: this.canEdit ? 960 : 560,
        title: this.canEdit
          ? this.$t(`offerMatch.btn.${ this.isNew ? 'new': 'edit' }`)
          : this.$t('offerMatch.singular'),
        persistent: this.canEdit,
        actions: this.canEdit
      };
    },
    headers() {
      return [
        {
          key: 'offer',
          text: this.$t('inputs.customer.label'),
          mobile: true,
          filter: {
            is: 'api-field',
            key: 'customerId',
            api: 'customer',
            itemSearch: 'name',
            itemText: 'name',
            clearable: true,
            multiple: true,
            chips: true
          }
        },
        {
          key: 'user',
          text: this.$t('inputs.alias.label'),
          hide: !!this.userId,
          filter: !this.userId && {
            is: 'api-field',
            key: 'userId',
            api: 'users',
            itemSearch: 'name',
            itemText: v => [ v.name, v.surname ].filter( Boolean ).join(' '),
            clearable: true,
            multiple: true,
            chips: true
          }
        },
        {
          key: 'time',
          text: this.$t('inputs.time.label'),
          display: displayDate,
          filter: false
        },
        {
          key: 'observations',
          text: this.$t('inputs.observations.label'),
        },
        {
          key: 'people',
          text: this.$t('inputs.people.label2'),
          filter: { is: 'number-field', min: 0 }
        },
        {
          key: 'status',
          text: this.$t('inputs.status.label'),
          width: 120,
          filter: {
            is: 'select-field',
            clearable: true,
            items: this.statusItems
          }
        }
      ];
    },
    userId() {
      return Number( this.$route.params.user ) || 0;
    },
    bookingStatus() {
      return Object.values( BOOKING_STATUS ).reduce(( obj, key ) => {
        obj[key] = this.$t('inputs.bookingStatus.' + key );
        return obj;
      },{});
    },
    statusItems() {
      return Object.keys( this.bookingStatus ).map( value => ({
        value: Number( value ),
        text: this.bookingStatus[value]
      }));
    },
    filters() {
      return this.userId
        ? [{ field: 'user', intValue: this.userId }]
        : [];
    }
  },
  methods: {
    edit( item ) {

      this.dialog = true;
      this.isNew = !item;

      if ( item ) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }

      this.$refs.form && this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.model = {
          id: -1,
          status: 1,
          ...item
        };
      });
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    sanitize( model ) {
      return {
        ...model,
        offer: undefined,
        user: undefined,
        people: model.offer ? model.offer.people : 1,
        offerId: model.offer ? model.offer.id : null,
        userId: model.user ? model.user.id : null
      };
    },
    save() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.model = this.$refs.form.model;
        this.$store
          .dispatch( 'api/offerMatch/set', this.sanitize( this.model ))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          });
      }
    }
  }
}
</script>
