<template>
  <v-form
    ref="form"
    class="pa-2"
    @submit.prevent="onSave"
  >

    <v-row>
      <v-col cols="12" :md="canEdit ? 6 : 5">

        <select-field
          v-if="canEdit"
          v-model="model.status"
          :label="$t('inputs.status.label')"
          :rules="rules.status"
          :items="statusItems"
        />

        <div v-else-if="model.status" class="mb-3">
          <strong class="subtitle-2">{{ $t('inputs.status.label') }}:</strong><br/>
          <span :class="{[`${statusColor[model.status]}--text`]: !!statusColor[model.status]}">
            {{ $t('inputs.bookingStatus.' + model.status ) }}
          </span>
        </div>

        <template v-if="canEdit">

          <label class="caption">
            {{ $t('inputs.time.label') }}
          </label>

          <div class="d-flex">

            <date-field
              v-model="date"
              :rules="rules.date"
              placeholder="dd/mm/yyyy"
              clearable
            />

            <time-field
              v-model="time"
              class="shrink ml-3"
              max-width="200"
              min-width="200"
              :rules="rules.time"
              placeholder="hh:mm"
              returnDate
              clearable
            />

          </div>
        </template>

        <div v-else class="mb-3">
          <strong class="subtitle-2">{{ $t('inputs.time.label') }}:</strong><br/>
          <span>{{ displayDate( model.time, true ) }}</span>
        </div>

        <number-field
          v-if="canEdit"
          v-model="model.people"
          :label="$t('inputs.people.label2')"
          :rules="rules.people"
          min="0" int
        />

        <div v-else class="mb-3">
          <strong class="subtitle-2">{{ $t('inputs.people.label2') }}:</strong><br/>
          <span>{{ model.people }}</span>
        </div>

        <textarea-field
          v-if="canEdit"
          v-model="model.observations"
          :label="$t('inputs.observations.label')"
          rows="4"
        />

        <div v-else class="mb-6">
          <strong class="subtitle-2">{{ $t('inputs.observations.label') }}:</strong><br/>
          <span>{{ model.observations }}</span>
        </div>

      </v-col>

      <v-divider
        v-if="!canEdit"
        class="my-3"
        vertical
      />

      <v-col cols="12" :md="canEdit ? 6 : 7">

        <v-card v-if="canEdit || model.user" class="mb-3 pa-2" outlined>

          <api-field
            v-if="canEdit"
            v-model="model.user"
            api="users"
            item-search="name"
            :item-text="computeUserName"
            :filters="userFilters"
            :label="$t('inputs.alias.label')"
            :rules="rules.user"
            return-object
            clearable
          />

          <p v-else class="px-2 mb-1">
            <router-link :to="`/app/users/${model.user.id}`">
              {{ model.user.name }} {{ model.user.surname }}
            </router-link>
          </p>

          <div class="px-2" v-if="model.user">

            <p v-if="model.user.email" class="mb-1">
              <v-icon color="black" small>
                mdi-email
              </v-icon> {{ model.user.email }}
            </p>

            <p v-if="model.user.phone" class="mb-1">
              <v-icon color="black" small>
                mdi-phone
              </v-icon> {{ model.user.phone }}
            </p>

          </div>
        </v-card>

        <v-card v-if="canEdit || offer" class="mb-3 pa-2" outlined>

          <api-field
            v-if="canEdit"
            :value="model.offer"
            api="offer"
            item-search="customer"
            item-text="customer"
            :label="$t('inputs.customer.label')"
            :rules="rules.offer"
            :compute="computeOffers"
            @input="onInputOffer"
            return-object
            clearable
          >
            <template v-slot:item="{ item }">
              <v-list-item-content style="max-width:390px">
                <div class="subtitle-2 d-flex align-center">
                  {{ item.customer }}
                  <v-spacer/>
                  <span class="caption">
                    Max. personas: <span class="primary--text">{{ item.people }}</span>
                  </span>
                </div>
                <div class="three-line-text caption mt-1">
                  {{ item.conditions }}
                </div>
              </v-list-item-content>
            </template>
          </api-field>

          <p v-else-if="offer.customerData" class="px-2 mb-1">
            <router-link :to="`/customer?id=${offer.customerData.id}`">
              {{ offer.customer }}
            </router-link>
          </p>

          <div class="px-2" v-if="offer && offer.customerData">

            <div class="mb-1 caption">
              <strong>
                {{ $t('inputs.people.label') }}:
              </strong> <span class="subtitle-2">
                {{ offer.people }}
              </span>
            </div>

            <p v-if="offer.customerData.mail" class="mb-1">
              <v-icon color="black" small>
                mdi-email
              </v-icon> {{ offer.customerData.mail }}
            </p>

            <p v-if="offer.customerData.phone" class="mb-1">
              <v-icon color="black" small>
                mdi-phone
              </v-icon> {{ offer.customerData.phone }}
            </p>

          </div>

          <btn
            v-if="!isNew && model.offer"
            :to="`/offer?id=${model.offer.id}`"
            icon-right="mdi-eye-outline"
            color="primary"
            class="mt-3"
            block
          >
            {{ $t('inputs.offer.label') }}
          </btn>

        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SelectField from '@/components/form/SelectField';
import DateField from '@/components/form/DateField';
import TimeField from '@/components/form/TimeField';
import ApiField from '@/components/form/ApiField';
import NumberField from '@/components/form/NumberField';
import TextareaField from '@/components/form/TextareaField';
import { USER_ROLES, BOOKING_STATUS_COLOR } from '@/utils/constants';
import { required, max } from '@/utils/rules';
import { displayDate } from '@/utils';
import { mapState } from 'vuex';

export default {
  components: {
    TextareaField,
    NumberField,
    SelectField,
    DateField,
    TimeField,
    ApiField
  },
  props: {
    value: Object,
    isNew: Boolean,
    save: Function,
    statusItems: Array,
    canEdit: Boolean
  },
  data() {
    const model = { ...this.value };
    model.offer = this.computeOffer( model.offer );
    return {
      model,
      date: model.time,
      time: model.time,
      offer: model.offer,
      maxPeople: null,
      statusColor: BOOKING_STATUS_COLOR,
      userFilters: [
        { field: 'role', intValue: USER_ROLES.USER }
      ]
    }
  },
  computed: {
    ...mapState( 'auth', [ 'user' ]),
    rules() {
      return {
        user: [
          required( this.$t('inputs.alias.rules.required2'))
        ],
        offer: [
          required( this.$t('inputs.offer.rules.required'))
        ],
        date: [
          required( this.$t('inputs.date.rules.required'))
        ],
        time: [
          required( this.$t('inputs.time.rules.required'))
        ],
        people: [
          required( this.$t('inputs.people.rules.required2')),
          this.maxPeople && max(
            this.maxPeople,
            this.$t('inputs.people.rules.max', { n: this.maxPeople })
          )
        ].filter( Boolean )
      };
    }
  },
  watch: {
    value( model ) {
      this.model = { ...model };
      this.date = this.time = this.model.time;
      this.offer = this.model.offer = this.computeOffer( this.model.offer );
      this.maxPeople = this.offer ? this.offer.people : null;
    },
    date: 'combineTime',
    time: 'combineTime'
  },
  methods: {
    displayDate,
    onSave() {
      this.$emit( 'input', this.model );
      this.save && this.$nextTick( this.save );
    },
    combineTime() {

      if ( ! this.date || ! this.time ) return;

      const date = new Date( this.date );
      const time = new Date( this.time );

      date.setHours( time.getHours());
      date.setMinutes( time.getMinutes());

      this.model.time = date.getTime();
    },
    computeUserName( data ) {
      return [ data.name, data.surname ].filter( Boolean ).join(' ');
    },
    computeOffer( offer ) {
      if ( offer && typeof offer.customer !== 'string' ) {
        offer = { ...offer };
        offer.customerData = offer.customer;
        offer.customer = offer.customer.name;
      }
      return offer;
    },
    computeOffers( data ) {
      return data.map( this.computeOffer );
    },
    onInputOffer( offer ) {
      this.offer = this.model.offer = offer;
      this.maxPeople = this.offer ? this.offer.people : null;
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    }
  }
}
</script>
